import React, { useState, useEffect } from 'react';
import { User, Briefcase, BookOpen, Code, Mail, Phone, Linkedin, Github, Calendar, MessageSquare, Moon, Sun } from 'lucide-react';
import ChatBot from './components/ChatBot';

const CVApp = () => {
  const [activeTab, setActiveTab] = useState('about');
  const [isDarkMode, setIsDarkMode] = useState(true);

  useEffect(() => {
    // Load Calendly CSS
    const link = document.createElement('link');
    link.href = "https://assets.calendly.com/assets/external/widget.css";
    link.rel = "stylesheet";
    document.head.appendChild(link);

    // Load Calendly JS
    const script = document.createElement('script');
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);

    /*script.onload = () => {
      window.Calendly.initBadgeWidget({ 
        url: 'https://calendly.com/m-bassi-mail', 
        text: 'Book a meeting', 
        color: '#0069ff', 
        textColor: '#ffffff', 
      });
    };*/

    return () => {
      document.head.removeChild(link);
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDarkMode]);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const experiences = [
    {
      title: "AI Tech Lead",
      company: "Swarovski AG, Zürich",
      period: "2023 - Present",
      description: "Defined and executed enterprise-wide AI strategy, spearheading integration of Generative AI solutions across design, marketing, customer service, IT, and digital. Developed production-ready RAG systems and automatic document classification.",
    },
    {
      title: "Data Scientist, Product Lead, Analytics Solution",
      company: "Swarovski AG, Zürich",
      period: "2020 - 2023",
      description: "Managed the implementation of Swarovski's Customer Data Platform for Marketing and CRM team. Mentored high-performing team of 4 data scientists. Engineered and deployed automated ML models for KPI dashboards.",
    },
    {
      title: "Data Analyst",
      company: "HotelBeds Group, Zürich",
      period: "2018 - 2020",
      description: "Delivered predictive analytics solutions for hotel demand forecasting and dynamic pricing models. Provided dashboarding and data visualizations.",
    },
    {
      title: "Technology & Strategy Analyst (FinTech)",
      company: "Deloitte Consulting, Milan",
      period: "2016 - 2017",
      description: "Advised financial services and FinTech clients on data strategy and automation opportunities.",
    },
    {
      title: "Freelance AI & Analytics Consultant",
      company: "Various Projects",
      period: "2016 - Current",
      description: "Provided strategic AI/ML expertise to startups and enterprises across industries including FinTech. Developed NLP-powered chatbot, increasing customer engagement by 30%.",
    },
  ];

  return (
    <div className={`min-h-screen p-4 sm:p-8 transition-colors duration-300 ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gradient-to-br from-blue-500 to-purple-600 text-white'}`}>
      <div className={`max-w-6xl mx-auto rounded-xl shadow-2xl overflow-hidden backdrop-filter backdrop-blur-lg transition-colors duration-300 ${isDarkMode ? 'bg-gray-800' : 'bg-white bg-opacity-10'}`}>
        {/* Header */}
        <header className="p-8 text-center relative">
          <button 
            onClick={toggleDarkMode} 
            className="absolute top-4 right-4 p-2 rounded-full transition-colors duration-300 hover:bg-opacity-20 hover:bg-gray-200"
          >
            {isDarkMode ? <Sun size={24} /> : <Moon size={24} />}
          </button>
          <h1 className="text-4xl font-bold mb-2">Matteo Bassi</h1>
          <p className="text-xl mb-4">AI Data Science Lead</p>
          <div className="flex justify-center space-x-4">
            <a href="mailto:m.bassi.mail@gmail.com" className="hover:text-blue-300 transition-colors"><Mail size={24} /></a>
            <a href="tel:+41764588232" className="hover:text-blue-300 transition-colors"><Phone size={24} /></a>
            <a href="https://www.linkedin.com/in/matteo-bassi/" target="_blank" rel="noopener noreferrer" className="hover:text-blue-300 transition-colors"><Linkedin size={24} /></a>
            <a href="https://github.com/MBassi91" target="_blank" rel="noopener noreferrer" className="hover:text-blue-300 transition-colors"><Github size={24} /></a>
          </div>
        </header>
        
        {/* Navigation */}
        <nav className="flex flex-wrap justify-center mb-8">
          {['about', 'experience', 'skills', 'education', 'contact', 'AI chat'].map((tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`px-4 py-2 m-2 rounded-full transition-all duration-300 ${
                activeTab === tab 
                  ? `${isDarkMode ? 'bg-blue-600 text-white' : 'bg-white text-blue-600'} shadow-lg transform scale-110` 
                  : `${isDarkMode ? 'text-white hover:bg-gray-700' : 'text-white hover:bg-white hover:bg-opacity-20'}`
              }`}
            >
              {tab === 'AI chat' ? <MessageSquare size={20} className="inline mr-2" /> : null}
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          ))}
        </nav>
        
        {/* Main Content */}
        {/* Main Content */}
        <main className="p-4 sm:p-8">
          <div className="transition-all duration-300 transform">
            {activeTab === 'about' && (
              <div className="space-y-4 animate-fade-in">
                <h2 className="text-2xl font-semibold mb-4 flex items-center"><User className="mr-2" /> About Me</h2>
                <p className="text-lg">
                  As an AI & Analytics Tech Lead with over a decade of experience, I'm passionate about harnessing the power of AI to drive innovation and create tangible business impact. My journey has taken me from crafting predictive models for the travel industry to spearheading enterprise-wide AI strategies for luxury brands.
                </p>
                <p className="text-lg">
                  I've led the charge in integrating cutting-edge AI technologies across multiple departments, from design to customer service. My expertise lies in developing production-ready RAG systems, implementing automatic document classification, and managing complex data platforms that fuel marketing and CRM initiatives.
                </p>
                <p className="text-lg">
                  I thrive on building and leading high-performing, diverse teams. My approach combines technical acumen with strong product management skills, allowing me to bridge the gap between advanced AI concepts and practical business applications. I'm known for my ability to troubleshoot complex technical issues and develop scalable solutions that stand the test of time.
                </p>
                <p className="text-lg">
                  Beyond the technical aspects, I'm committed to fostering a culture of continuous learning and collaboration. I believe that the best innovations come from diverse perspectives and open exchange of ideas. Whether I'm mentoring data scientists or collaborating with cross-functional teams, I always aim to inspire and be inspired.
                </p>
                <p className="text-lg">
                  When I'm not immersed in the world of AI, you might find me exploring the latest developments in tech. I'm always eager to connect with fellow tech enthusiasts and industry leaders to discuss the future of AI and its potential to transform businesses and society.
                </p>
              </div>
            )}
            
            {activeTab === 'experience' && (
              <div className="space-y-8 animate-fade-in">
                <h2 className="text-2xl font-semibold mb-4 flex items-center"><Briefcase className="mr-2" /> Experience</h2>
                <div className="space-y-6">
                  {experiences.map((exp, index) => (
                    <div key={index} className={`p-4 rounded-lg transition-colors duration-300 ${isDarkMode ? 'bg-gray-700' : 'bg-white bg-opacity-10'}`}>
                      <h3 className="font-semibold text-xl">{exp.title}</h3>
                      <p className="text-lg font-medium">{exp.company}</p>
                      <p className="text-sm mb-2">{exp.period}</p>
                      <p>{exp.description}</p>
                    </div>
                  ))}
                </div>
              </div>
            )}



          {activeTab === 'skills' && (
            <div className="animate-fade-in">
              <h2 className="text-2xl font-semibold mb-4 flex items-center"><Code className="mr-2" /> Skills</h2>
              <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                {['Python', 'SQL', 'GCP', 'AWS', 'Tableau', 'PowerBI', 'Product Management', 'React'].map((skill) => (
                  <div key={skill} className="bg-white bg-opacity-10 p-4 rounded-lg text-center">
                    <p className="font-semibold">{skill}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
          
          {activeTab === 'education' && (
            <div className="space-y-4 animate-fade-in">
              <h2 className="text-2xl font-semibold mb-4 flex items-center"><BookOpen className="mr-2" /> Education</h2>
              <div className="space-y-4">
                <div className="bg-white bg-opacity-10 p-4 rounded-lg">
                  <h3 className="font-semibold">MPhil in Statistics</h3>
                  <p>Università Bocconi, Milan (2017-2018)</p>
                </div>
                <div className="bg-white bg-opacity-10 p-4 rounded-lg">
                  <h3 className="font-semibold">MSc in Econ & Finance - Quant. Finance</h3>
                  <p>Università di Milano (2014-2016)</p>
                </div>
                <div className="bg-white bg-opacity-10 p-4 rounded-lg">
                  <h3 className="font-semibold">BA in Economics</h3>
                  <p>Università di Milano (2011-2014)</p>
                </div>
              </div>
            </div>
          )}

          {activeTab === 'AI chat' && (
              <div className="animate-fade-in h-[600px]">
                <h2 className="text-2xl font-semibold mb-4 flex items-center">
                  <MessageSquare className="mr-2" /> Chat with Matteo's AI Assistant
                </h2>
                <div className="bg-white rounded-lg shadow-xl h-full">
                  <ChatBot fullScreen={true} />
                </div>
              </div>
            )}

          
            {activeTab === 'contact' && (
              <div className="space-y-8 animate-fade-in">
                <h2 className="text-2xl font-semibold mb-4 flex items-center"><Mail className="mr-2" /> Contact Me</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  <div className="bg-white bg-opacity-10 p-6 rounded-lg">
                    <h3 className="text-xl font-semibold mb-4 flex items-center"><MessageSquare className="mr-2" /> Send a Message</h3>
                    <form className="space-y-4">
                      <input type="text" placeholder="Your Name" className="w-full p-2 rounded bg-white bg-opacity-20 placeholder-white placeholder-opacity-70" />
                      <input type="email" placeholder="Your Email" className="w-full p-2 rounded bg-white bg-opacity-20 placeholder-white placeholder-opacity-70" />
                      <textarea placeholder="Your Message" rows="4" className="w-full p-2 rounded bg-white bg-opacity-20 placeholder-white placeholder-opacity-70"></textarea>
                      <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors">Send Message</button>
                    </form>
                  </div>
                  <div className="bg-white bg-opacity-10 p-6 rounded-lg">
                    <h3 className="text-xl font-semibold mb-4 flex items-center"><Calendar className="mr-2" /> Book a Meeting</h3>
                    <p className="mb-4">I'm always excited to connect with fellow professionals and discuss potential collaborations or opportunities.</p>
                    <a 
                      href="https://calendly.com/m-bassi-mail" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors inline-flex items-center"
                    >
                      <Calendar className="mr-2" /> Schedule a Meeting
                    </a>
                  </div>
                </div>
              </div>
            )}
        </div>
        </main>
      </div>
    </div>
  );
};

export default CVApp;