import React, { useState, useRef, useEffect } from 'react';
import { MessageSquare, Send, X, User, Bot } from 'lucide-react';
import ReactMarkdown from 'react-markdown';

const ChatBot = ({ fullScreen = false }) => {
  const [isOpen, setIsOpen] = useState(fullScreen);
  const [messages, setMessages] = useState([
    { text: "Hi there! I'm Matteo's AI assistant. How can I help you today?", isBot: true }
  ]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [botResponse, setBotResponse] = useState('');
  const messagesEndRef = useRef(null);

  const toggleChat = () => setIsOpen(!isOpen);

  const handleInputChange = (e) => setInput(e.target.value);

  const getApiUrl = () => {
    if (process.env.NODE_ENV === 'development') {
      return 'http://localhost:8888/.netlify/functions/chat';
    } else {
      return '/.netlify/functions/chat';
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (input.trim() === '') return;

    setMessages(prev => [...prev, { text: input, isBot: false }]);
    setInput('');
    setIsLoading(true);

    try {
      const apiUrl = getApiUrl();
      console.log('Calling API at:', apiUrl); // For debugging

      const response = await fetch(apiUrl, {
        method: 'POST',
        body: JSON.stringify({ message: input }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      setBotResponse('');
      setMessages(prev => [...prev, { text: '', isBot: true }]);

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        
        const chunk = decoder.decode(value);
        const lines = chunk.split('\n\n');
        
        for (const line of lines) {
          if (line.startsWith('data: ')) {
            const jsonData = line.slice(6);
            if (jsonData === '[DONE]') break;
            
            try {
              const data = JSON.parse(jsonData);
              const token = data.content;
              if (token) {
                setBotResponse(prev => prev + token);
              }
            } catch (error) {
              console.error('Error parsing JSON:', error);
            }
          }
        }
      }
    } catch (error) {
      console.error('Error calling chat function:', error);
      setMessages(prev => [...prev, { text: "Sorry, I'm having trouble connecting right now. Please try again later.", isBot: true }]);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (botResponse) {
      setMessages(prev => {
        const newMessages = [...prev];
        newMessages[newMessages.length - 1].text = botResponse;
        return newMessages;
      });
    }
  }, [botResponse]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const renderChatContent = () => (
    <>
      <div className="flex-1 overflow-y-auto p-4 space-y-4">
        {messages.map((message, index) => (
          <div key={index} className={`flex ${message.isBot ? 'justify-start' : 'justify-end'}`}>
            <div className={`flex items-start space-x-2 max-w-[80%] ${message.isBot ? 'flex-row' : 'flex-row-reverse'}`}>
              <div className={`w-8 h-8 rounded-full flex items-center justify-center flex-shrink-0 ${message.isBot ? 'bg-blue-500' : 'bg-green-500'}`}>
                {message.isBot ? <Bot size={20} className="text-white" /> : <User size={20} className="text-white" />}
              </div>
              <div className={`p-3 rounded-lg ${message.isBot ? 'bg-blue-100 text-blue-900' : 'bg-green-100 text-green-900'}`}>
                <ReactMarkdown>{message.text}</ReactMarkdown>
              </div>
            </div>
          </div>
        ))}
        {isLoading && (
          <div className="flex justify-start">
            <div className="flex items-start space-x-2">
              <div className="w-8 h-8 rounded-full flex items-center justify-center flex-shrink-0 bg-blue-500">
                <Bot size={20} className="text-white" />
              </div>
              <div className="bg-gray-200 p-3 rounded-lg">
                <div className="typing-indicator">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
      <form onSubmit={handleSubmit} className="p-4 border-t border-gray-200">
        <div className="flex space-x-2">
          <input
            type="text"
            value={input}
            onChange={handleInputChange}
            placeholder="Type your message..."
            className="flex-1 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-900 placeholder-gray-500"
          />
          <button 
            type="submit" 
            className="bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 transition-colors disabled:bg-blue-300"
            disabled={isLoading}
          >
            <Send size={20} />
          </button>
        </div>
      </form>
    </>
  );

  if (fullScreen) {
    return (
      <div className="flex flex-col h-full bg-white rounded-lg shadow-xl">
        <div className="bg-blue-500 text-white p-4 rounded-t-lg">
          <h3 className="font-bold text-lg">Chat with Matteo's AI Assistant</h3>
        </div>
        {renderChatContent()}
      </div>
    );
  }

  return (
    <div className="fixed bottom-4 right-4 z-50">
      {isOpen && (
        <div className="bg-white rounded-lg shadow-xl w-96 h-[32rem] flex flex-col">
          <div className="bg-blue-500 text-white p-4 rounded-t-lg flex justify-between items-center">
            <h3 className="font-bold">Chat with Matteo's AI</h3>
            <button onClick={toggleChat} className="text-white hover:text-gray-200">
              <X size={20} />
            </button>
          </div>
          {renderChatContent()}
        </div>
      )}
      {!isOpen && (
        <button 
          onClick={toggleChat}
          className="bg-blue-500 text-white p-4 rounded-full shadow-lg hover:bg-blue-600 transition-colors"
        >
          <MessageSquare size={24} />
        </button>
      )}
    </div>
  );
};

export default ChatBot;